<template>
  <div id="fa-points">
    <section class="news-gradient">
      <img
        class="d-none d-md-block w-100"
        src="https://static.fasney.me/sff/fasney/image/products/fapoints/fap-header-pc.jpg"
        alt="fa-points"
      >
      <img
        class="d-md-none img-fluid"
        src="https://static.fasney.me/sff/fasney/image/products/fapoints/fap-header-m.jpg"
        alt="fa-points"
      >
      <img
        class="d-none d-md-block w-100"
        src="https://static.fasney.me/sff/fasney/image/products/fapoints/fap-content-pc.jpg?v=1108"
        alt="fa-points"
      >
      <img
        class="d-md-none img-fluid"
        src="https://static.fasney.me/sff/fasney/image/products/fapoints/fap-content-m.jpg?v=1108"
        alt="fa-points"
      >
      <img
        class="d-none d-md-block w-100"
        src="https://static.fasney.me/sff/fasney/image/products/fapoints/fap-footer-pc.jpg?v=1026"
        alt="fa-points"
      >
      <img
        class="d-md-none img-fluid"
        src="https://static.fasney.me/sff/fasney/image/products/fapoints/fap-footer-m.jpg?v=1026"
        alt="fa-points"
      >
    </section>
  </div>
</template>

<script>
export default {
  name: 'FaPoints'
}
</script>
