<template>
  <div id="postpay">
    <PostpayJumbotron />
    <PostpayInfo />
    <PostpaySecurity />
  </div>
</template>

<script>
import PostpayJumbotron from '@/components/products/postpay/PostpayJumbotron'
import PostpayInfo from '@/components/products/postpay/PostpayInfo'
import PostpaySecurity from '@/components/products/postpay/PostpaySecurity'

export default {
  name: 'Postpay',
  metaInfo: {
    title: 'fasney 無卡享樂-後支付',
    titleTemplate: null,
    meta: [
      {
        name: 'description',
        content: 'fasney 無卡享樂 後支付，無卡零利率，買完下個月再付。'
      }
    ]
  },
  components: {
    PostpayJumbotron,
    PostpayInfo,
    PostpaySecurity
  }
}
</script>

<style></style>
