<template>
  <div id="loan">
    <LoanJumbotron />
    <LoanInfo />
    <LoanFooter />
  </div>
</template>

<script>
import LoanJumbotron from '@/components/products/loan/LoanJumbotron'
import LoanInfo from '@/components/products/loan/LoanInfo'
import LoanFooter from '@/components/products/loan/LoanFooter'

export default {
  name: 'Loan',
  metaInfo: {
    title: 'fasney 即刻金援-好好貸',
    titleTemplate: null
  },
  components: {
    LoanJumbotron,
    LoanInfo,
    LoanFooter
  }
}
</script>

<style></style>
