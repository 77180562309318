<template>
  <div id="installment">
    <InstalmentJumbotron />
    <InstalmentRule />
    <InstalmentFooter />
  </div>
</template>

<script>
import InstalmentJumbotron from '@/components/products/instalment/InstalmentJumbotron'
import InstalmentRule from '@/components/products/instalment/InstalmentRule'
import InstalmentFooter from '@/components/products/instalment/InstalmentFooter'

export default {
  name: 'Postpay',
  metaInfo: {
    title: 'fasney 靈活繳款-慢慢還',
    titleTemplate: null
  },
  components: {
    InstalmentJumbotron,
    InstalmentRule,
    InstalmentFooter
  }
}
</script>

<style></style>
